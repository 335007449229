import { Footer } from 'components/Footer';
import Header from 'components/Header';
import ScrollToTop from 'components/ScrollToTop';
import React, { useContext, useMemo, useState } from 'react';
import * as S from './baselayout.styles';

interface Props {
  children: React.ReactNode;
}

/**
 * You can use this context to hide the header and the footer.
 * To use it, you can just use this context and add setShowHeaderAndFooter(false);
 */
export const BaseLayoutContext = React.createContext({
  /**
   * Warning! This is setState() method and should not be called directly inside a render.
   * https://github.com/facebook/react/issues/18178#issuecomment-595846312
   *
   * Tip: use useEffect().
   */
  setShowHeaderAndFooter: (_show: boolean) => {},
  showHeaderAndFooter: true,
});

const BaseLayoutContextProvider = ({ children }: Props) => {
  const [showHeaderAndFooter, setShowHeaderAndFooter] = useState(false);

  const context = useMemo(() => {
    return {
      showHeaderAndFooter,
      setShowHeaderAndFooter,
    };
  }, [showHeaderAndFooter, setShowHeaderAndFooter]);

  return (
    <BaseLayoutContext.Provider value={context}>
      <BaseLayout>{children}</BaseLayout>
    </BaseLayoutContext.Provider>
  );
};

const BaseLayout = ({ children }: Props) => {
  const baseLayoutContext = useContext(BaseLayoutContext);

  return (
    <S.Layout>
      <ScrollToTop />
      {baseLayoutContext.showHeaderAndFooter && <Header />}
      <S.Wrapper>{children}</S.Wrapper>
      {baseLayoutContext.showHeaderAndFooter && <Footer />}
    </S.Layout>
  );
};

export default BaseLayoutContextProvider;
