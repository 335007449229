import RelayService from '@vodafonepub/relay-service';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function TagPageVisitComponent() {
  const location = useLocation();

  useEffect(() => {
    const path = `/my${location.pathname}`;

    RelayService.trigger('routeChange.ready', {
      page: {
        route: path,
      },
    });
  }, [location]);

  return null;
}

export default TagPageVisitComponent;
